import React from "react";

const About = () => {
  return (
    <div className="bg-gray-200 p-8">
      <h1 className="text-4xl font-bold mb-4">About</h1>
      <p className="text-lg">
        Your partner in software development excellence.
      </p>
    </div>
  );
};

export default About;
