import React from "react";
import {image,fullstack_icon,risk_icon,security_icon,cloud_icon,data_icon,consulting_icon} from "../imports.js";
var header = "Let’s make your product a success"
var x = " SSL is your partner in digital innovation. We fuse strategic thinking with standout design and development to deliver uniquely tailored solutions."
var section2_p="From fresh ideas to fully-realized designs, we cover every aspect of your digital presence."
const Home = () => {
  return (
<>
<div className="main">
  <section className="card">
      <div className="card_welcome_div">
        <p className="card_welcome">Welcome</p>
      </div>
      <section className="section_title">
        <h1 className="card_header">{header}</h1>
        <img src={image} className="img_card"></img>
        <p className='card_text'>{x}</p>
      </section>
  </section>

    <div className="introduction">
      <div className="element_div">
        <p className="element"> What we do </p>
      </div>
        <section className="section">
        <h2 className="section_header">We create digital products that help you get ahead</h2>
        <p className="section_p">{section2_p}</p>
        </section>
  </div>
  <div className="frame">
      <div className="card_frame">
        <div className="div_section_card_frame">
          <img src={fullstack_icon} alt="" className="card_frame_img"></img>
          <div>
          <h2 className="card_frame_header">Full Stack development</h2>
          <p className="card_frame_p">We help you identify your goals and develop a plan to achieve them.</p>
          </div>
        </div>  
      </div>
      <div className="card_frame">
        <div div className="div_section_card_frame">
          <img src={risk_icon} alt="" className="card_frame_img"></img>
          <div>
          <h2 className="card_frame_header">Risk analysis</h2>
          <p className="card_frame_p">We create beautiful, intuitive interfaces that your users will love.</p>
          </div>
        </div>
      </div>
      <div className="card_frame">
        <div div className="div_section_card_frame">
          <img src={security_icon} alt="" className="card_frame_img"></img>
          <div>
          <h2 className="card_frame_header">Security analysis</h2>
          <p className="card_frame_p">We create beautiful, intuitive interfaces that your users will love.</p>
          </div>
        </div>
      </div>
      <div className="card_frame">
        <div div className="div_section_card_frame">
          <img src={cloud_icon} alt="" className="card_frame_img"></img>
          <div>
          <h2 className="card_frame_header">Comercial Cloud applications</h2>
          <p className="card_frame_p">We create beautiful, intuitive interfaces that your users will love.</p>
          </div>
        </div>
      </div>
      <div className="card_frame">
        <div div className="div_section_card_frame">
          <img src={data_icon} alt="" className="card_frame_img"></img>
          <div>
          <h2 className="card_frame_header">Data Analysis and Visualiton</h2>
          <p className="card_frame_p">We create beautiful, intuitive interfaces that your users will love.</p>
          </div>
        </div>
      </div>
      <div className="card_frame">
        <div div className="div_section_card_frame">
          <img src={consulting_icon} alt="" className="card_frame_img"></img>
          <div>
          <h2 className="card_frame_header">Consulting</h2>
          <p className="card_frame_p">We create beautiful, intuitive interfaces that your users will love.</p>
          </div>
        </div>
      </div>
    </div>
    </div>
</>


  );
};

export default Home;
