
import React from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

class Contact extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      email: '',
      subj: '',
      message: '',
      captchaV: '',
      mailSent: false,
      error: null
    };
    this.recaptcha = React.createRef();
  }

  handleFormSubmit = e => {

    e.preventDefault();
    const captchaValue = this.recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      this.state.captchaV = captchaValue;
      axios({
        method: 'post',
        url: `email_contact.php`,
        headers: { 'content-type': 'application/json' },
        data: this.state
      })
        .then(result => {
          this.setState({
            mailSent: result.data.sent
          })
        })
        .catch(error => this.setState({ error: error.message }));
    }
  }
  render() {
    return (
      <form>
        < label > Name</label >
        <input required type="text" id="fname" name="fullname" placeholder="Your name.."
          value={this.state.fname}
          onChange={e => this.setState({ fname: e.target.value })}
        />

        <label>Email</label>
        <input required type="email" id="email" name="email" placeholder="Your email address.."
          value={this.state.email}
          onChange={e => this.setState({ email: e.target.value })}
        />

        <label>Subject</label>
        <input required type="subject" id="subject" name="subject" placeholder="Message Subject.."
          value={this.state.subj}
          onChange={e => this.setState({ subj: e.target.value })}
        />

        <label>Message</label>
        <textarea required id="message" name="message" placeholder="Your message.. "
          onChange={e => this.setState({ message: e.target.value })}
          value={this.state.message}
        ></textarea>
        <input  type="submit" onClick={e => this.handleFormSubmit(e)} value="Submit" />
        

        <ReCAPTCHA ref={this.recaptcha} sitekey={"6LfuygwpAAAAANhUtCEnS3W79tTd6usFLC4Erzyr"} />
      </form >
    );
  }
}

export default Contact;
