import React from "react";
import { Link } from "react-router-dom";
import logo from "../ssl-logo-zip-file/svg/logo-no-background.svg"
import black_logo from "../images/ssl-high-resolution-logo-black-transparent.png"
const Navbar = () => {
  return (
    <div className="navbar">
        <div> 
        <img src={black_logo} className="logo" alt="Logo" border="0" width="100" height="100" />
        </div>
        <div className="buttons">
            <li className="nav_box">
                <Link to="/">Home</Link>
            </li>
            <li className="nav_box">
                <Link to="/services">Services</Link>
            </li>
            <li className="nav_box">
                <Link to="/contact">Contact</Link>
            </li>
            <li className="nav_box">
                <Link to="/about">About</Link>
            </li>
      </div>
      <div> 
      </div>
    </div>
  );
};
export default Navbar;